exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-escuela-de-magia-js": () => import("./../../../src/pages/escuela-de-magia.js" /* webpackChunkName: "component---src-pages-escuela-de-magia-js" */),
  "component---src-pages-eventos-publicos-js": () => import("./../../../src/pages/eventos-publicos.js" /* webpackChunkName: "component---src-pages-eventos-publicos-js" */),
  "component---src-pages-gl-empresas-js": () => import("./../../../src/pages/gl/empresas.js" /* webpackChunkName: "component---src-pages-gl-empresas-js" */),
  "component---src-pages-gl-escola-de-maxia-js": () => import("./../../../src/pages/gl/escola-de-maxia.js" /* webpackChunkName: "component---src-pages-gl-escola-de-maxia-js" */),
  "component---src-pages-gl-eventos-publicos-js": () => import("./../../../src/pages/gl/eventos-publicos.js" /* webpackChunkName: "component---src-pages-gl-eventos-publicos-js" */),
  "component---src-pages-gl-index-js": () => import("./../../../src/pages/gl/index.js" /* webpackChunkName: "component---src-pages-gl-index-js" */),
  "component---src-pages-gl-maxia-educativa-js": () => import("./../../../src/pages/gl/maxia-educativa.js" /* webpackChunkName: "component---src-pages-gl-maxia-educativa-js" */),
  "component---src-pages-gl-particulares-js": () => import("./../../../src/pages/gl/particulares.js" /* webpackChunkName: "component---src-pages-gl-particulares-js" */),
  "component---src-pages-gl-sobre-nos-js": () => import("./../../../src/pages/gl/sobre-nos.js" /* webpackChunkName: "component---src-pages-gl-sobre-nos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magia-educativa-js": () => import("./../../../src/pages/magia-educativa.js" /* webpackChunkName: "component---src-pages-magia-educativa-js" */),
  "component---src-pages-particulares-js": () => import("./../../../src/pages/particulares.js" /* webpackChunkName: "component---src-pages-particulares-js" */),
  "component---src-pages-posts-json-slug-js": () => import("./../../../src/pages/{postsJson.slug}.js" /* webpackChunkName: "component---src-pages-posts-json-slug-js" */),
  "component---src-pages-shows-json-es-slug-js": () => import("./../../../src/pages/{showsJson.es__slug}.js" /* webpackChunkName: "component---src-pages-shows-json-es-slug-js" */),
  "component---src-pages-shows-json-gl-slug-js": () => import("./../../../src/pages/{showsJson.gl__slug}.js" /* webpackChunkName: "component---src-pages-shows-json-gl-slug-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */)
}

